import { Outlet } from "@remix-run/react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { MetaFunction } from "@remix-run/cloudflare";

export const meta: MetaFunction = ({ location }) => {
  const BASE_URL = "https://charatsukuai.limo.media";

  const ogImageUrl = `${BASE_URL}/static/og/default.png`;
  const ogUrl = `${BASE_URL}${location.pathname}`;

  return [
    {
      title:
        "キャラつくAI | プロフィールをもとにAIがイメージしたあなたの姿は？",
    },
    {
      name: "description",
      content: "プロフィールをもとにAIがイメージしたあなたの姿は？",
    },
    { property: "og:image", content: ogImageUrl },
    { property: "og:image:width", content: "1200" },
    { property: "og:image:height", content: "630" },
    { property: "og:title", content: "キャラつくAI" },
    {
      property: "og:description",
      content: "プロフィールをもとにAIがイメージしたあなたの姿は？",
    },
    { property: "og:type", content: "website" },
    { property: "og:url", content: ogUrl },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@limo_media" },
  ];
};

export default function Layout() {
  return (
    <main>
      <Header />
      <Outlet />
      <Footer />
    </main>
  );
}
